/** @jsxImportSource @emotion/react */
import React from "react";
import {
  Typography,
  Box,
} from "@mui/material";
import styles from "./styles";

const Toppers = () => {
return (
    <Box sx={styles.containerCss}>
        <Typography variant="h4" component="h2" sx={styles.AboutUs} gutterBottom>
            Celebrating Our Top Achievers
        </Typography>
        <Box>
            <img src="/images/toppers.jpeg" width='95%' alt="Our Top Achievers" style={styles.staffImage} />
        </Box>
    </Box>
);
};

export default Toppers;
