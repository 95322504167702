/** @jsxImportSource @emotion/react */
import React from "react";
import {
  Typography,
  Box,
} from "@mui/material";
import styles from "./styles";

const ProudAchivers = () => {
return (
    <Box sx={styles.containerCss}>
        <Typography variant="h4" component="h2" sx={styles.AboutUs} gutterBottom>
            Our Proud Achievers
        </Typography>
        <Typography variant="body1" component="p" sx={styles.description}>
            Our alumni have consistently secured top positions in various fields, showcasing their dedication and excellence.
        </Typography>
        <Box>
            <img src="/images/prodAchievers.jpeg" width='95%' alt="Our Top Achievers" style={styles.staffImage} />
        </Box>       
    </Box>
);
};

export default ProudAchivers;
