/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Toolbar, Button } from "@mui/material";
import styles from "./styles";

import {
  Drawer,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const Header = ({ scrollToSection }) => {
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const DrawerBar = () => (
    <Drawer
      sx={styles.muiDrower}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <div>
        <IconButton onClick={handleDrawerClose}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      {/* case "home":
        homeRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        aboutRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "toppers":
        topperRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "facilities":
        facilitiesRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "proudAchivers":
        proudAchiversRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        homeRef.current.scrollIntoView({ behavior: "smooth" });
    } */}
      <List sx={styles.widthMenu}>
        <ListItem key="Home">
          <ListItemText
            primary="Home"
            onClick={() => scrollToSection("home")}
          />
        </ListItem>
        <ListItem key="About">
          <ListItemText
            primary="About"
            onClick={() => scrollToSection("about")}
          />
        </ListItem>
        <ListItem key="toppers">
          <ListItemText
            primary="Toppers"
            onClick={() => scrollToSection("toppers")}
          />
        </ListItem>

        <ListItem key="facilities">
          <ListItemText
            primary="Facilities"
            onClick={() => scrollToSection("facilities")}
          />
        </ListItem>
        <ListItem key="proudAchivers">
          <ListItemText
            primary="Proud Achivers"
            onClick={() => scrollToSection("proudAchivers")}
          />
        </ListItem>
      </List>
    </Drawer>
  );

  return (
    <Toolbar sx={styles.toolbarCss}>
      <div css={styles.leftIcon}>
        <img src="/images/logo.jpg" alt="Mandi24 Logo" width={80} height={80} />
      </div>
      {!open && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="end"
          sx={styles.iconButton}
        >
          <MenuIcon />
        </IconButton>
      )}
      {DrawerBar()}
      <div css={styles.rightdivItem}>
        <Button
          to="/"
          css={styles.rightItem}
          exact
          activeClassName="active"
          onClick={() => scrollToSection("home")}
        >
          Home
        </Button>
        <Button
          css={styles.rightItem}
          activeClassName="active"
          onClick={() => scrollToSection("about")}
        >
          About
        </Button>
        <Button
          css={styles.rightItem}
          activeClassName="active"
          onClick={() => scrollToSection("toppers")}
        >
          Toppers
        </Button>
        <Button
          css={styles.rightItem}
          activeClassName="active"
          onClick={() => scrollToSection("facilities")}
        >
          Facilities
        </Button>
        <Button
          css={styles.rightItem}
          activeClassName="active"
          onClick={() => scrollToSection("proudAchivers")}
        >
          Proud Achivers
        </Button>
      </div>
    </Toolbar>
  );
};

export default Header;
