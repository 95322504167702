import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const Footer = () => {
    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',
                bottom: 0,
                backgroundColor: 'lightgray',
                color: 'black',
                textAlign: 'center',
                py: 2,
            }}
        >
            <Container maxWidth="lg">
                <Typography variant="body1" align="center">
                    © 2025 Rajkumar Janta Inter College. All rights reserved. Designed and developed by <a href="https://www.linkedin.com/in/aashishtyagig/" target="_blank" rel="noopener noreferrer">Aashish Tyagi</a>
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;