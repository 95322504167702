import React, { useRef } from "react";
import Header from "./components/header";
import HeaderBanner from "./components/headerBanner";
import AboutUs from "./components/aboutUs";
import Toppers from "./components/toppers";
import ProudAchivers from "./components/prodAch";
import Facilities from "./components/facilities";
import Messages from "./components/message";
import Footer from "./components/footer";

// import ContactUs from './components/contactUs';
// import Notification from './components/notification';
import "./App.css";

function App() {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const topperRef = useRef(null);
  const facilitiesRef = useRef(null);
  const proudAchiversRef = useRef(null);
  const scrollToSection = (section) => {
    switch (section) {
      case "home":
        homeRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        aboutRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "toppers":
        topperRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "facilities":
        facilitiesRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "proudAchivers":
        proudAchiversRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        homeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="App">
      <Header scrollToSection={scrollToSection} />
      <div ref={homeRef}>
        <HeaderBanner />
      </div>
      <div ref={aboutRef}>
        <AboutUs />
      </div>
      <div ref={topperRef}>
        <Toppers />
      </div>
      <div ref={facilitiesRef}>
        <Facilities />
      </div>
      <div>
        <Messages />
      </div>
      <div ref={proudAchiversRef}>
        <ProudAchivers />
      </div>
      {/* <OurStaff /> */}
      <Footer />
    </div>
  );
}

export default App;
