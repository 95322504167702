import React from "react";
import { Box, Typography } from "@mui/material";
import styles from "./styles";

const HeaderBanner = () => {
  return (
    <Box sx={styles.bannerCss}>
      <img
        src="/images/BannerUpdatedNew.jpg"
        width={1920}
        height={400}
        alt="Banner"       
        style={styles.bannerImgCss}
      />
      <Box sx={styles.bannerTextCss}>
        <Typography variant="h4" sx={styles.bannerTextHeader} gutterBottom>
          Rajkumar Janta Inter College Falodha Muzaffarnager
        </Typography>
        <Typography variant="body1">Where Potential Meets Performance And Crafting Tomorrow&apos;s Legends ,</Typography>
      </Box>
    </Box>
  );
};

export default HeaderBanner;
