import { css } from "@emotion/react";

const styles = {
  toolbarCss: css`
    background-color: white;
    display: flex;
    justify-content: space-between;
  `,
  rightItem: css`
    display: flex;
    gap: 10px;
    color: #000;
    text-decoration: none;
    padding: 5px;
    width: auto;
    text-align: center;
    border-radius: 5px;
    
    font-size: 18px;
    &:hover {
      color: #0006;
    }
  `,
  rightdivItem: css`
    @media (max-width: 600px) {
      display: none;
    }
    @media (min-width: 601px) {
      display: flex;
      gap: 10px;
    }
  `,
  headerFont: css`
    font-weight: 300;
    color: #000;
    margin-left: 10px;
  `,
  iconButton: css`
    @media (max-width: 600px) {
      float: right;
      position: absolute;
      right: 15px;
    }
    @media (min-width: 601px) {
      display: none;
    }
  `,
  leftIcon: css`
    item-align: left;
    flex-direction: row;
    display: flex;
  `,
  headerCss: css`
    color: #000;
    font-size: 35px;
    font-weight: 700;
    margin-top: 20px;
  `,
  muiDrower: css`
    @media (max-width: 600px) {
      width: 340px;
      flex-shrink: 0;
      & .MuiDrawer-paper {
        width: 240px;
      }
    }
    @media (min-width: 601px) {
      display: none;
    }
  `,
  widthMenu: css`
    width: 240px;
  `,
  headerBanner: css`
    background-color: #f5f5f5;
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  `,
  headerBannerContent: css`
    font-size: 24px;
    color: #333;
    margin: 0;
    padding: 0;
  `,
  bannerImgCss: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
    align: "center",
  },
  bannerTextCss: css`
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    height: 100%;
    text-shadow: 1px 1px 2px lightgray;
    color: #fff;
    font-weight: 700;
    @media (max-width: 600px) {
      font-size: 12px;
      width: 80%;
      top: 50%;
    }
    @media (min-width: 601px) {
      font-size: 24px;
      width: 90%;
      top: 100%;
    }
  `,
  bannerCss: css`
    position: relative;
    display: flex;
    background-color: #f5f5f5;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  `,
  containerCss: css`
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 20px;
    text-align: center;
    item-align: center;
  `,
  staffImage: css`
    width: "80%",
    height: "auto",
    objectFit: "cover",
    align: "center",

  `,
  AboutUs: css`
    color: #000;
    font-size: 35px;
    font-weight: 700;
    margin-top: 20px;
    text-align: center;
  `,
  bannerTextHeader: css`
    font-size: 34px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    @media (max-width: 600px) {
      font-size: 18px;
    }
    @media (min-width: 601px) {
      font-size: 45px;
    }
  `,
  description: css`
    color: #000;
    font-size: 18px;
    font-weight: 300;
    margin-top: 20px;
    text-align: center;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
  `,
  messageText: css`
    color: #000;
    font-size: 18px;
    font-weight: 300;
    margin-top: 20px;
    text-align: justify;

  `,
  CardMediaImage: css`
    width: 300px;
    height: 300px;
    align: center;
    item-align: center;
    object-fit: cover;
  `,
};

export default styles;
