import React from "react";
import { Container, Box, Typography, Avatar, Grid } from "@mui/material";
import styles from "./styles";

const PrincipalMessage = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            p={2}
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              boxShadow: 3,
              mt: 4,
            }}
          >
            <Avatar
              alt="Principal"
              src="/images/Gaurav.jpeg"
              sx={{ width: 300, height: 300, mb: 2 }}
            />
            <Typography variant="h5" component="h1" gutterBottom>
              Principal's Message
            </Typography>
            <Typography variant="body1" component="p" sx={styles.messageText}>
              The school has always emphasized on three key aspects which are
              quintessential to create conducive environment where constructive
              learning may take place. First of all, flexibility towards
              changing practices and acceptance towards the emerging ones,
              secondly, bringing best people in the team who are dedicated to
              the real objectives of education and nurturing them further and
              lastly on providing child-friendly amenities yet keeping strong
              touch with the Nature in mind. We do follow traditional teaching
              practices but with a difference. Our teachers are free to
              experiment new ways and means to evolve better teaching practices.
              Secondly, we ensure that every teacher shall get adequate
              training, resource material and motivation in order to perform
              better and more efficiently. Every year, we adopt new approach
              towards imparting knowledge which keeps the freshness and
              creativity intact and encourage the students to learn in a natural
              manner. <br />
              Our association with different progressive professionals and
              organizations has provided us enough and desired direction and
              their active involvement has brought desirable level of confidence
              and exposure to the students. We as a family comprising of our
              dedicated mentors and disciples are faring well but are not
              complacent and have all the desires to achieve more and better.
              <br />
              Wish all of you Luck!
              <br />
              <br />
              Gaurav Tyagi (Principal)
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            p={2}
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              boxShadow: 3,
              mt: 4,
            }}
          >
            <Avatar
              alt="Manager"
              src="/images/NaveenManager.JPG"
              sx={{ width: 300, height: 300, mb: 2 }}
            />     
             <Typography variant="h5" component="h1" gutterBottom>
              Manager's Message
            </Typography>      
            <Typography variant="body1" component="p" sx={styles.messageText}>
              No learning may be termed as meaningful and relevant if attained
              in isolation as none can view its impact and significance. We
              believe in providing best learning environment and adopt measures
              to provide adequate exposure which certainly turn each of our
              students into confident being and prepare them to face any
              challenge, no matter how big or tough. The school may not have
              advantage of being located in a metropolitan city but the school
              attempts and provides best exposure which may be termed on par
              with what students in bigger cities experience. <br />
              The school encourages students to come out to participate in
              various co-scholastic initiatives and provides suitable platform
              so that they may be able to get rid of any sort of hesitation and
              hone their skills with the support and guidance of experts and
              professionals from different parts of the globe and the outcomes
              obtained are simply unimaginable.
              <br /> The entire team at the school is focused towards bringing
              more value for the betterment and development of students with
              different temperaments, inclinations and interests and we are
              quite confident that our students will be able to leave imprints
              in all spheres in times to come.
              <br />
              <br />
              Naveen Kumar Tyagi (Manager)
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            p={2}
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              boxShadow: 3,
              mt: 4,
            }}
          >
            <Avatar
              alt="Chairmen"
              src="/images/vinodTyagi.jpeg"
              sx={{ width: 300, height: 300, mb: 2 }}
            />
            <Typography variant="h5" component="h1" gutterBottom>
              Chairman's Message
            </Typography>
            <Typography variant="body1" component="p" sx={styles.messageText}>
              When people talk of colossal infrastructural establishments,
              pampering facilities and no exposure to natural habitat at all, it
              suggests that we are treading towards wrong path. We, at Uma Devi
              Children’s Academy believe in providing best education which
              covers sports facilities as well. The school has developed sports
              facilities, organizes some selected sports events and encourages
              the students to participate in different sports events and our
              students bring laurels to the school on a continuous basis.
              Moreover, the school has formed association with several experts
              and organizations in the field of sports and has clear intention
              of providing enough exposure to the students so that they may
              excel not only in academics but also in sports as it is
              quintessential for physical and mental well-being and strengthens
              the notion of working as a team during all the times.
              <br />
              <br />
              Dr Vinod Kumar Tyagi (Chairman)
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PrincipalMessage;
