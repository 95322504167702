"use client";
/** @jsxImportSource @emotion/react */
import React from "react";
import { Container, Typography, Paper, Box } from "@mui/material";
import styles from "./styles";

const AboutUs = () => {
  return (
    <Container>
      <Paper>
        <Box p={4} sx={styles.aboutUsCoin}>
          <Typography variant="h4" sx={styles.AboutUs} gutterBottom>
            About Us
          </Typography>
          <Typography variant="body1" sx={styles.messageText}  paragraph>
            Welcome to Rajkumar Janta Inter College, Falodha. We are a leading
            educational institution in the region.
          </Typography>
          <Typography variant="body1" sx={styles.messageText} paragraph>
            Our mission is to provide quality education to our students. We
            offer a wide range of courses to help students achieve their
            academic goals. Our faculty members are highly qualified and
            experienced in their respective fields. We are committed to
            providing a supportive and inclusive learning environment for all
            our students. We believe in the holistic development of our students
            and encourage them to participate in extracurricular activities. We
            are proud of our students achievements and are dedicated to helping
            them reach their full potential.
          </Typography>
          <Typography variant="body1" sx={styles.messageText}  paragraph>
            We are constantly striving to improve our facilities and services to
            provide the best possible learning experience for our students. We
            welcome feedback from our students and are always looking for ways
            to enhance their educational experience. We are committed to
            providing a high-quality education to all our students and preparing
            them for success in their future careers.
          </Typography>
          <Typography variant="body1" sx={styles.messageText}  paragraph>
            Thank you for choosing us. We look forward to serving you.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default AboutUs;
